/* Happ Color */

.happ-color {
    color: #0050ff !important;
}

.happ-background-color {
    background: #0050ff !important;
}

.btn-validation-grid{
    text-align: center;
    margin-bottom: 20px !important;
    margin-top: 30px !important;
}

.primary-bg-color{
    background-color: #0050ff !important;
    border-color: #0050ff !important;
    padding: 12px 30px;
    position: relative;
    font-size: 12px;
    min-width: auto;
    color: #fff !important;
    border-radius: 3px;
}

@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url(../fonts/DMSans-Regular.ttf);
}

@font-face {
    font-family: 'DMSansBold';
    src: local('DMSansBold'), url(../fonts/DMSans-Bold.ttf);
}

a, p, div, span, input, label {
    font-family: 'DMSans'!important;
}

h1, h2, h3, h4, h5,h6, button, span{
    font-family: 'DMSansBold'!important;
}

.text-area-observation {
    height: 120px;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    /* color: grayColor; */
    font-size: 14px;
    /* font-family: '"DMSans", sans-serif'; */
    font-weight: 400;
    line-height: 1.42857;
    opacity: 1;
    border: 2px solid rgba(0, 0, 0, 0.42);
    border-radius: 7px;
}