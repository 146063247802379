.content-icons{
    padding: 10px 5px !important;
    background: #0050ff !important;
    border-radius: 7px !important;
}

.content-icons-grey svg{
    width: 50px !important;
    height: 50px !important;
    margin: 10px 10px 0px !important;
    font-size: 36px !important;
    text-align: center !important;
    line-height: 56px !important;
}

.content-icons-indi svg{
    width: 50px !important;
    height: 50px !important;
    margin: -5px auto -2px auto !important;
    font-size: 36px !important;
    text-align: center !important;
    display: table;
    line-height: 56px !important;
}

.content-icons svg{
    width: 50px !important;
    height: 50px !important;
    padding-left: 0.9px;
}
.content-icons path, .content-icons-grey path {
    fill-rule: evenodd;
    clip-rule: evenodd;
}

.icon-login-field svg{
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

