.select-operativo-container{
    width: 50%;
    margin: 0 auto 20px auto;
}
.global-container *{
    color: rgba(0, 0, 0, 0.87) !important;
}
.content-inputs-patients, .content-health-benefits{
    width: 80%;
    margin: 25px auto;
}

.form-operative .select-custom-operative .MuiInputBase-root,
.content-inputs-patients .content-dni .MuiInputBase-root,
.content-inputs-profile .MuiInputBase-root,
.content-inputs-health-benefits .MuiInputBase-root,
.patient-birthday{
    border: 2px solid rgba(0, 0, 0, 0.42);
    border-radius: 7px;
}

.patient-birthday{
    padding-left: 15px;
    padding-top: 0px;
    padding-bottom: 5px;
}

.patient-birthday input{
    padding-left: 0px !important;
    font-size: 14px !important;
    background-image: none !important;
    padding-top: 14px !important;
}

.content-inputs-profile .grid-birthday{
    margin-top: 27px;
}



.content-inputs-patients .content-dni .MuiFormLabel-root{
    top: 16px;
    left: 10px;
    font-size: 14px !important;
}

.content-inputs-profile .MuiFormLabel-root{
    top: 18px;
    left: 10px;
    font-size: 14px !important;
}

.content-inputs-patients .content-dni .MuiInputLabel-shrink,
.content-inputs-profile .MuiInputLabel-shrink{
    transform: translate(0, -6.5px) scale(0.85);
    transform-origin: top left;
}

.content-inputs-patients .content-dni .MuiInputBase-root,
.content-inputs-profile .MuiInputBase-root,
.content-inputs-health-benefits .MuiInputBase-root{
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 4px;
}

.content-inputs-health-benefits .MuiFormLabel-root{
    top: 3px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 12.5px !important;
}

.content-inputs-health-benefits .MuiInputLabel-shrink {
    transform: translate(0, -9.5px) scale(0.95);
    transform-origin: top left;
}

.content-inputs-health-benefits .MuiInput-underline:before{
    border: none !important;
}

.show-section-true{
    display: block;
}
.show-section-false{
    display: none;
}
.content-inputs-health-benefits .MuiFormControl-root{
    padding-top: 14px;
}
.content-inputs-profile .MuiInputBase-root{
    margin-top: 27px;
}

.select-custom-operative .MuiInputLabel-shrink {
    transform: translate(0, -10.5px) scale(0.85);
    transform-origin: top left;
}

.select-custom-operative .MuiInputLabel-formControl{
    top: 10px;
    left: 10px;
}

.MuiSelect-select:focus{
    background: none !important;
}

.p_require{
    font-size: 12px;
    color: red !important;
}

.form-operative #mui-component-select-operative{
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.form-operative .select-custom-operative .MuiInputBase-root:before, .MuiInput-underline:after,
.form-operative .content-inputs-profile .MuiInput-underline:before,
.form-operative .content-inputs-patients .content-dni .MuiInput-underline:before{
    border: none !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none !important;
}

.content-inputs-patients .MuiFormControl-root,
.content-health-benefits .MuiFormControl-root{
    margin: 0 !important;
}

.content-inputs-patients .MuiGrid-root, 
.content-health-benefits .MuiGrid-root{
    position: relative;
}

.content-dni{
    width: 50%;
    margin: 0 auto;
}

.btn-search-dni{
    width: 100%;
    margin-top: 28.66px !important;
}

.input-dni-validate .MuiFormControl-root{
    width: 100%;
}

.center-select{
    margin: 0 auto !important;
}

.health-befenefit{
    padding: 8px;
    border: 1px solid #333333;
    border-radius: 7px;
    margin-bottom: 10px !important;
}

.health-befenefit .makeStyles-switchChecked-278 .makeStyles-switchIcon-275,
.jss278 .jss275{
    border-color: #0050ff !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
    background-color: #0050ff !important;
}

.health-befenefit .makeStyles-label-262 {
    margin-top: 3px;
}

.first-item-health-befenefit{
    text-align: center;
    border-right: 1px solid #333333;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-inputs-health-benefits{
    align-items: center;
    justify-content: center;
}

.content-inputs-health-benefits .MuiTableCell-root{
    font-weight: 500 !important;
}

.equal{
    font-size: 21px;
}

.line{
    width: 0px;
    border: 1px solid;
    height: 74px;
    margin: 0 auto;
}
.divider{
    padding-left: 0 !important;
}

.content-inputs-health-benefits .makeStyles-cardHeader-210{
    display: none;
}

.content-inputs-health-benefits .MuiTable-root button{
    visibility: hidden;
}

.color-white .MuiButton-label{
    color: #fff !important;
}

.btn-center{
    text-align:center;
}

.p_error{
    text-align:center;
}

.error_patient{
    margin: 0 auto;
    padding: 5px;
    border-radius: 7px;
    color: #fff !important;
    width: 50%;
    background: #0050ff !important;
    text-align: center;
}


@media(max-width: 700px) {
    .select-operativo-container,
    .content-dni{
        width: 100%;
    }
    .first-item-health-befenefit{
        border-right: none;
    }
    .divider{
        display: none;
    }
    .content-inputs-health-benefits .MuiFormControlLabel-root{
        margin: 0 auto;
    }
    .content-inputs-health-benefits .makeStyles-cardBody-232, .jss232{
        width: 100%;
        padding: 0;
    }
}
